import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import Seo from "../components/Seo"
import { useTranslation } from "gatsby-plugin-react-i18next";

const MonthlyArchive = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes
const {t,i18n} = useTranslation()
  return (
    <Layout>
      <Helmet>
        {/* Add hrefLang attribute in the head */}
        <link rel="alternate" hrefLang="en" href="https://staging.quranplayermp3.com/monthly-archive/" />
        <link rel="alternate" hreflang="ar" href="https://staging.quranplayermp3.com/ar/monthly-archive/" />
        <link rel="alternate" hreflang="x-default" href="https://staging.quranplayermp3.com/monthly-archive/" />
      </Helmet>
      <Seo title="Monthly Archive" />
      <div className="main">
        <div className="container">
          <div className="centered-page-header text-center">
            <h1 className="title">{t("Monthly Archive")}</h1>
            {/* <div className="description">
                    Use this if you want to show small description
                </div> */}
          </div>
          <div className="archive-wrap">
            {posts.map((post, index) => (
              <div
                className={`archive-post-card pm-${post.frontmatter.dateMonth}`}
                key={index}
              >
                <div className="pm">{t(post.frontmatter.dateMonthYear)}</div>
                <article className="archive-post">
                  <div className="inner">
                    <time
                      className="post-date"
                      dateTime={t(post.frontmatter.date)}
                    >
                      {t(post.frontmatter.dateMonthDay)} -
                    </time>
                    <h2 className="title">
                      <Link hrefLang={i18n.language} to={post.fields.slug}>
                        {t(post.frontmatter.title)}
                      </Link>
                    </h2>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MonthlyArchive

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }}
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { published: { ne: false } }
        fileAbsolutePath: { regex: "/content/posts/" }
      }
    ) {
      nodes {
        ...PostQueryFragment
        frontmatter {
          dateMonthYear: date(formatString: "MMMM YYYY")
          dateMonthDay: date(formatString: "MMM DD")
          dateMonth: date(formatString: "M")
        }
      }
    }
  }
`
